import slideImg from '@/assets/back.png';
import { mapGetters } from 'vuex';

export default {
  name: 'UIServerImage',
  props: {
    message: Object,
  },
  data() {
    return {
      slideImg,
    };
  },
  computed: {
    ...mapGetters('theme', ['systemAvatar']),
  },
};
